<template>
  <div>
    <quillEditor
      ref="base-quill-editor"
      v-model="model"
      :options="options"
    />
    <b-row
      class="py-1"
      align-v="center"
      align-h="end"
    >
      <b-col cols="auto">
        <b-button
          class="p-1 text-nowrap rounded-sm"
          @click.stop="$emit('cancel-button-click')"
        >
          {{ cancelButtonText }}
        </b-button>
        <b-button
          variant="primary"
          class="p-1 ml-1 text-nowrap rounded-sm"
          @click="$emit('confirm-button-click', localModel)"
        >
          {{ confirmButtonText }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'BaseEditor',
  components: {
    quillEditor,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    cancelButtonText: {
      type: String,
      default: 'Cancelar',
    },
    confirmButtonText: {
      type: String,
      default: 'Añadir comentario',
    },
  },
  data() {
    return {
      actionsVisible: false,
      options: {
        placeholder: 'Deje su comentario...',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ['clean'],
            ['link', 'video'],
          ],
        },
      },
      localModel: this.value,
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.localModel = value
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style scoped>
</style>
