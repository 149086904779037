<template>
  <BaseCard
    v-if="commentsCardVisible"
    title="comentarios"
    :footer-visible="false"
    :loading="loading"
    collapsable
  >
    <div
      v-for="commentItem in comments"
      :key="commentItem.id"
    >
      <b-row
        class="p-1"
        align-v="center"
      >
        <b-col
          cols="auto"
          class="py-1 pr-1 pl-2"
        >
          <b-avatar
            size="lg"
            :src="commentItem.user.avatar_url"
          />
        </b-col>
        <b-col>
          <div class="font-weight-bold pb-1">
            {{ commentItem.user.name }}
          </div>
          <template v-if="commentItem.editEnabled">
            <BaseEditor
              :value="commentItem.comment"
              confirm-button-text="Actualizar comentario"
              @cancel-button-click="$set(commentItem, 'editEnabled', false)"
              @confirm-button-click="handleUpdateButtonClick(commentItem, $event)"
            />
          </template>
          <template v-else>
            <div v-html="commentItem.comment" />
          </template>
          <div class="text-light">
            {{ commentItem.comment_date | formatDate }}
          </div>
        </b-col>
        <b-col cols="auto">
          <div class="actions-width">
            <template v-if="canUserUpdateComment(commentItem.user)">
              <b-link
                class="d-inline-block pr-1 text-indigo"
              >
                <feather-icon
                  v-b-tooltip.hover 
                  title="Editar"
                  icon="Edit2Icon"
                  size="18"
                  @click="$set(commentItem, 'editEnabled', true)"
                />
              </b-link>
              <b-link
                class="d-inline-block text-danger pr-1"
                @click="handleDeleteIconClick(commentItem)"
              >
                <feather-icon
                  v-b-tooltip.hover 
                  title="Eliminar"
                  icon="TrashIcon"
                  size="18"
                />
              </b-link>
            </template>
          </div>
        </b-col>
      </b-row>
      <hr class="m-0">
    </div>
    <hr class="m-0">
    <b-overlay
      :show="commentBoxLoading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <BaseEditor
        v-model="content"
        v-access="{
          resource: $data.$constants.RESOURCES.RESOURCE_COMMENTS,
          resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
        }"
        class="p-1"
        @confirm-button-click="handleSaveCommentButtonClick"
        @cancel-button-click="content = ''"
      />
    </b-overlay>
  </BaseCard>
</template>

<script>
/* eslint-disable no-param-reassign */
import ApiRestService from '@/api/base-api'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import BaseEditor from '@/components/ui/editor/BaseEditor.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'BaseCommentCard',
  components: { BaseEditor, BaseCard },
  props: {
    filters: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      comments: [],
      content: '',
      loading: false,
      commentBoxLoading: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
      canAccessResource: 'canAccessResource',
    }),
    commentsCardVisible() {
      return (
        this.canAccessResource(this.$data.$constants.RESOURCES.RESOURCE_COMMENTS, this.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL)
        || this.canAccessResource(this.$data.$constants.RESOURCES.RESOURCE_COMMENTS, this.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_VIEW)
      )
    },
  },
  mounted() {
    this.loadComments()
  },
  methods: {
    async loadComments() {
      if (!this.commentsCardVisible) {
        return
      }

      this.loading = true
      try {
        const response = await ApiRestService.list({ filters: this.filters }, this.$data.$constants.RESOURCES.RESOURCE_COMMENTS)
        this.comments = response.data
      } finally {
        this.loading = false
      }
    },
    async handleSaveCommentButtonClick() {
      if (!this.content || this.content.trim() === '') {
        return
      }

      this.commentBoxLoading = true
      try {
        await ApiRestService.create({ ...this.filters, comment: this.content }, this.$data.$constants.RESOURCES.RESOURCE_COMMENTS)
        await this.loadComments()
        this.content = ''
      } finally {
        this.commentBoxLoading = false
      }
    },
    async handleUpdateButtonClick(commentItem, newComment) {
      this.loading = true
      try {
        await ApiRestService.edit(
          commentItem.id,
          { ...commentItem, comment: newComment },
          this.$data.$constants.RESOURCES.RESOURCE_COMMENTS,
        )
        commentItem.comment = newComment
        commentItem.editEnabled = false
      } finally {
        this.loading = false
      }
    },
    async handleDeleteIconClick(comment) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará el comentario',
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.delete(comment.id, this.$data.$constants.RESOURCES.RESOURCE_COMMENTS)
        await this.loadComments()
      } finally {
        this.loading = false
      }
    },
    canUserUpdateComment(commentUser) {
      return (
          commentUser?.id === this.user?.id
          && this.canAccessResource(this.$data.$constants.RESOURCES.RESOURCE_COMMENTS, this.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL)
      )
    },
  },
}
</script>

<style scoped>
.actions-width {
  width: 70px;
}
</style>
